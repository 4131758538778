import { useState, createContext, useRef } from "react";
// import Header from "./Header/Header";
// import Home from "./Home/Home";
import backlapse from "./assets/backlapse.mp4";
import logo from "./assets/logo2.png";
import whats from "./assets/whats.png";

export const AppContext = createContext();

const App = () => {
  const [things, setThings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const contactRef = useRef(null);
  const contactDefaultClass = "contact animate__animated";

  const onClickContact = () => {
    const mensagem = `Olá! Vim pelo site 3dcoisas.com e gostaria de saber mais a respeito do serviço de impressão 3D.`
    window.open(encodeURI(`https://wa.me/+5511976362085?text=${mensagem}`));
  };

  const onMouseEnter = () => {
    contactRef.current.className = `${contactDefaultClass} animate__flash`;
  };

  const onMouseLeave = () => {
    contactRef.current.className = contactDefaultClass;
  };

  return (
    <AppContext.Provider value={{ things, setThings, isLoading, setIsLoading }}>
      <video id="background-video" autoPlay muted loop playsInline ref={videoRef}>
        <source src={backlapse} type="video/mp4" /> Your browser does not
        support the video tag.
      </video>

      <div className="app">
        <div className="app-header">
          <img src={logo} className="logo" alt="" />
        </div>

        <div className="app-body">
          <div className="title">Traga sua ideia à realidade!</div>
          <div>
            <div className="subtitle">Somos especializados em impressão 3D</div>
            <div>Trabalhamos com equipamentos e filamentos de ponta</div>
          </div>

          <div>
            Entre em contato e faça um orçamento:
            <div
              ref={contactRef}
              className={contactDefaultClass}
              onClick={onClickContact}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <img src={whats} className="whats" alt="" />{" "}
              <div className="whats-label">Whatsapp</div>
            </div>
          </div>

          <div></div>
        </div>

        {/* 
          <Header />
          <Home /> 
        */}
      </div>
    </AppContext.Provider>
  );
};

export default App;
